<template>
  <div class="carouselBar">
    <el-carousel>
      <el-carousel-item v-for="(v,i) in carouselList" :key="i">
        <img :src="v.url" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    carouselList: {
      type: Array,
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>

.carouselBar {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  /deep/ .el-carousel__container {
    height: 600px;
  }

  /deep/ .el-carousel__indicators--horizontal {
    bottom: 40px;

    .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 2px;
    }
  }


}

</style>
